import React, { useMemo } from 'react';

import Icon from './Icon';

export default function RadarIcon({
  text = 'Radar',
  variant = 'high',
}: { text?: string; variant: 'high' | 'low'; }): JSX.Element {
  const iconToRender = useMemo(() => {
    if (variant === 'high') {
      return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26.8284 21.1716C28.3905 22.7337 28.3905 25.2663 26.8284 26.8284C25.2663 28.3905 22.7337 28.3905 21.1716 26.8284C19.6095 25.2663 19.6095 22.7337 21.1716 21.1716C22.7337 19.6095 25.2663 19.6095 26.8284 21.1716"
            stroke="#376EF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.3"
            d="M36.728 11.2715C29.698 4.24152 18.302 4.24152 11.272 11.2715C4.24201 18.3015 4.24201 29.6975 11.272 36.7275C18.302 43.7575 29.698 43.7575 36.728 36.7275C40.242 33.2135 41.96 28.6055 41.956 23.9995"
            stroke="#376EF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.7201 11.2793L26.8201 21.1793"
            stroke="#376EF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.7"
            d="M31.778 16.222C29.788 14.232 27.038 13 24 13C17.924 13 13 17.924 13 24C13 30.076 17.924 35 24 35C30.076 35 35 30.076 35 24"
            stroke="#376EF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }

    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.1716 26.8284C19.6095 25.2663 19.6095 22.7337 21.1716 21.1716C22.7337 19.6095 25.2663 19.6095 26.8284 21.1716C28.3905 22.7337 28.3905 25.2663 26.8284 26.8284C25.2663 28.3905 22.7337 28.3905 21.1716 26.8284"
          stroke="#B2B9C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.7"
          d="M11.272 36.7285C18.302 43.7585 29.698 43.7585 36.728 36.7285C43.758 29.6985 43.758 18.3025 36.728 11.2725C29.698 4.24248 18.302 4.24248 11.272 11.2725C7.75799 14.7865 6.03999 19.3945 6.04399 24.0005"
          stroke="#B2B9C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.2799 36.7207L21.1799 26.8207"
          stroke="#B2B9C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M16.222 31.778C18.212 33.768 20.962 35 24 35C30.076 35 35 30.076 35 24C35 17.924 30.076 13 24 13C17.924 13 13 17.924 13 24"
          stroke="#B2B9C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }, [variant]);
  return <Icon text={text}>{iconToRender}</Icon>;
}
